import Link from 'next/link';
import React, { ElementType, useCallback, useMemo } from 'react';

import { Icon } from '@/components/shared/element/icons';
import { CSS, styled } from '@/stitches.config';
import { IIcon } from '@/types/shared';
import { ITest, ITracking } from '@/types/tracking';

const ButtonIconStyled = styled('button', {
  all: 'unset',
  userSelect: 'none',
  position: 'relative',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  verticalAlign: 'middle',
  textAlign: 'center',
  margin: '$space-0',
  padding: '$space-0',
  cursor: 'pointer',
  borderRadius: '$rounded-1',
  '&::before': {
    content: '""',
    position: 'absolute',
    inset: '$space-0',
    borderRadius: 'inherit',
    backgroundColor: 'var(--bg-color)',
    pointerEvents: 'none',
    transition:
      'background-color var(--transition-duration) var(--transition-easing)',
  },
  '& i': {
    color: 'var(--icon-color)',
    position: 'relative',
    display: 'inline-block',
    zIndex: '1',
    pointerEvents: 'none',
  },
  '@hover': {
    '&:hover, [data-hover]:hover &': {
      '&::before': {
        backgroundColor: 'var(--hover-bg-color)',
      },
      '& i': {
        color: 'var(--hover-icon-color)',
      },
    },
  },
  '&:disabled, &.swiper-button-disabled': {
    pointerEvents: 'none',
    '--bg-color': 'var(--colors-gray300)',
    '--icon-color': 'var(--input-color-disabled)',
    '& i': {
      color: 'var(--input-color-disabled)',
    },
  },
  variants: {
    size: {
      xs: {
        size: '$size-6',
        minWidth: '$size-6',
        '& i': {
          size: '$size-4',
          minWidth: '$size-4',
        },
      },
      sm: {
        size: '$size-8',
        minWidth: '$size-8',
        '& i': {
          size: '$size-5',
          minWidth: '$size-5',
        },
      },
      md: {
        size: '$size-10',
        minWidth: '$size-10',
        '& i': {
          size: '$size-6',
          minWidth: '$size-6',
        },
      },
      lg: {
        size: '$size-12',
        minWidth: '$size-12',
        borderRadius: '$rounded-1-5',
        '& i': {
          size: '$size-6',
          minWidth: '$size-6',
        },
      },
      xl: {
        size: '$size-12',
        minWidth: '$size-12',
        borderRadius: '$rounded-1-5',
        '& i': {
          size: '$size-6',
          minWidth: '$size-6',
        },
        '@md': {
          size: '$size-14',
          minWidth: '$size-14',
          '& i': {
            size: '$size-6',
            minWidth: '$size-6',
          },
        },
      },
    },
    variant: {
      'button-icon-color-red': {
        '--bg-color': 'var(--colors-primary)',
        '--hover-bg-color': 'var(--colors-primary100)',
        '--icon-color': 'var(--colors-white)',
        '--hover-icon-color': 'var(--colors-white)',
      },
      'button-icon-color-blue': {
        '--bg-color': 'var(--colors-blue)',
        '--hover-bg-color': 'var(--colors-white)',
        '--icon-color': 'var(--colors-white)',
        '--hover-icon-color': 'var(--colors-blue)',
      },
      'button-icon-color-green': {
        '--bg-color': 'var(--colors-green)',
        '--hover-bg-color': 'var(--colors-white)',
        '--icon-color': 'var(--colors-white)',
        '--hover-icon-color': 'var(--colors-green)',
      },
      'button-icon-color-white': {
        '--bg-color': 'var(--colors-white)',
        '--hover-bg-color': 'var(--colors-gray900)',
        '--icon-color': 'var(--colors-gray900)',
        '--hover-icon-color': 'var(--colors-white)',
      },
      'button-icon-color-gray-light': {
        '--bg-color': 'var(--colors-gray300)',
        '--hover-bg-color': 'var(--colors-gray900)',
        '--icon-color': 'var(--colors-gray900)',
        '--hover-icon-color': 'var(--colors-gray300)',
      },
      'button-icon-color-gray': {
        '--bg-color': 'var(--colors-gray700)',
        '--hover-bg-color': 'var(--colors-white)',
        '--icon-color': 'var(--colors-white)',
        '--hover-icon-color': 'var(--colors-gray700)',
      },
      'button-icon-color-gray-dark': {
        '--bg-color': 'var(--colors-gray900)',
        '--hover-bg-color': 'var(--colors-white)',
        '--icon-color': 'var(--colors-white)',
        '--hover-icon-color': 'var(--colors-gray900)',
      },
      'button-icon-color-white-icon-blue': {
        '--bg-color': 'var(--colors-white)',
        '--hover-bg-color': 'var(--colors-blue)',
        '--icon-color': 'var(--colors-blue)',
        '--hover-icon-color': 'var(--colors-white)',
      },
      'button-icon-color-white-icon-green': {
        '--bg-color': 'var(--colors-white)',
        '--hover-bg-color': 'var(--colors-green)',
        '--icon-color': 'var(--colors-green)',
        '--hover-icon-color': 'var(--colors-white)',
      },
    },
    border: {
      true: {
        '&::before': {
          backgroundColor: '$transparent',
          border: '1px solid var(--bg-color)',
          transition:
            'border-color var(--transition-duration) var(--transition-easing), background-color var(--transition-duration) var(--transition-easing)',
        },
        '& i': {
          color: 'var(--hover-icon-color)',
        },
        '@hover': {
          '&:hover, [data-hover]:hover &': {
            '&::before': {
              backgroundColor: 'var(--bg-color)',
              borderColor: 'var(--bg-color)',
            },
            '& i': {
              color: 'var(--icon-color)',
            },
          },
        },
      },
    },
    isTransparent: {
      true: {
        '&::before': {
          backgroundColor: '$transparent',
        },
        '& i': {
          color: 'var(--hover-icon-color)',
        },
        '@hover': {
          '&:hover, [data-hover]:hover &': {
            '&::before': {
              backgroundColor: 'var(--bg-color)',
            },
            '& i': {
              color: 'var(--icon-color)',
            },
          },
        },
      },
    },
    shadow: {
      true: {
        bs: '$shadow-200',
      },
    },
    isCircle: {
      true: {
        borderRadius: '$rounded-full',
      },
    },
    isDisable: {
      true: {
        opacity: '0.2',
        pointerEvents: 'none',
      },
    },
  },
  compoundVariants: [
    {
      border: 'true',
      variant: 'button-icon-color-red',
      css: {
        '& i': {
          color: '$primary',
        },
      },
    },
    {
      isTransparent: 'true',
      variant: 'button-icon-color-red',
      css: {
        '& i': {
          color: '$primary',
        },
      },
    },
  ],
  defaultVariants: {
    variant: 'button-icon-color-red',
    size: 'md',
  },
});

export interface ButtonIconProps
  extends React.ComponentProps<typeof ButtonIconStyled> {
  /**
   * The content of the button.
   */
  children?: React.ReactNode;
  /**
   * The css styles for the component.
   */
  css?: CSS;
  /**
   * The class name to be applied to the component.
   */
  className?: string;
  /**
   * The ARIA label for the icon button.
   */
  label?: string;
  /**
   * The URL of the resource to be linked to when the button is clicked.
   * If specified, the button will render as an `<a>` element.
   */
  href?: string;
  /**
   * Specifies where to display the linked URL. Only applies when `href` is specified.
   */
  target?: '_self' | '_blank' | '_parent' | '_top';
  /**
   * Specifies whether to perform a prefetch of the linked URL. Only applies when `href` is specified.
   * Defaults to `false`.
   */
  prefetch?: boolean;
  /**
   * The icon to display inside the button.
   */
  icon?: IIcon;
  /**
   * The event handler for the click event.
   */
  onClick?: () => void;
  /**
   * The tracking info for the component.
   */
  tracking?: ITracking;
  /**
   * The test info for the component.
   */
  test?: ITest;
  /**
   * The component to be rendered.
   */
  component?: ElementType;
}

export const ButtonIcon: React.FC<ButtonIconProps> = React.memo(
  ({
    children,
    css,
    className,
    label,
    icon,
    onClick,
    tracking,
    test,
    href,
    target,
    prefetch = false,
    component = 'button',
    ...rest
  }) => {
    const handleClick = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!href) {
          event.preventDefault();
          onClick?.();
        }
      },
      [href, onClick]
    );

    const baseButtonIconProps = useMemo(
      () => ({
        css,
        className,
        size: rest.size,
        variant: rest.variant,
        border: rest.border,
        isTransparent: rest.isTransparent,
        shadow: rest.shadow,
        isCircle: rest.isCircle,
        isDisable: rest.isDisable,
        disabled: rest.disabled,
        'aria-label': label ?? 'button',
        'data-track': tracking?.dataTrack,
        'data-track-section': tracking?.dataTrackSection,
        'data-track-value': tracking?.dataTrackValue,
        'data-track-text': tracking?.dataTrackText,
        'data-track-url': tracking?.dataTrackUrl,
        'data-test': test?.dataTest,
      }),
      [
        css,
        className,
        label,
        rest.size,
        rest.variant,
        rest.border,
        rest.isTransparent,
        rest.shadow,
        rest.isCircle,
        rest.isDisable,
        rest.disabled,
        tracking?.dataTrack,
        tracking?.dataTrackSection,
        tracking?.dataTrackValue,
        tracking?.dataTrackText,
        tracking?.dataTrackUrl,
        test?.dataTest,
      ]
    );

    return href ? (
      <ButtonIconStyled
        as={Link}
        href={href}
        prefetch={prefetch}
        target={target}
        rel={target === '_blank' ? 'noopener noreferrer' : undefined}
        {...baseButtonIconProps}
      >
        {icon ? <Icon icon={icon} /> : children}
      </ButtonIconStyled>
    ) : (
      <ButtonIconStyled
        as={component}
        onClick={handleClick}
        {...baseButtonIconProps}
      >
        {icon ? <Icon icon={icon} /> : children}
      </ButtonIconStyled>
    );
  }
);
ButtonIcon.displayName = 'ButtonIcon';
