import logger from '@/lib/logger';
import type { LogService } from '@/lib/logger/log.type';

const formatLog = (input: Record<string, string>): string => {
  return Object.entries(input)
    .map(([key, value]) => `${key.toUpperCase()}|${value}`)
    .join('|');
};

const parseData = (data: any) => {
  if (!data) return '-';
  if (typeof data === 'string') {
    return data;
  }

  return JSON.stringify(data);
};
const send = async ({
  url,
  params,
  status,
  headers,
  data,
  message,
  error,
}: LogService) => {
  const log = {
    URL: parseData(url),
    PARAMS: parseData(params),
    STATUS: parseData(status),
    HEADERS: parseData(headers),
    DATA: parseData(data),
    ERROR: parseData(error),
    MESSAGE: parseData(message),
  };

  logger.error(`LOG_ERROR_SERVICE|${formatLog(log)}`);
};

export const datadogLogs = { send };
