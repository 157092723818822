import { useEffect } from 'react';

import {
  useLineBrowser,
  useSafariBrowser,
} from '@/components/shared/utility/browser';

export const useGlobalStyle = () => {
  const isLine = useLineBrowser();
  if (isLine) {
    document.documentElement.classList.add('is-line');
  }

  const isSafari = useSafariBrowser();

  if (isSafari) {
    document.documentElement.classList.add('is-safari');
  }

  useEffect(() => {
    const updateGlobal = () => {
      const scrollWidth = Math.max(
        document.body.clientWidth,
        document.documentElement.clientWidth
      );

      document.documentElement.style.setProperty('--vw', `${scrollWidth}px`);
      document.documentElement.style.setProperty(
        '--vh',
        `${window.innerHeight * 0.01}px`
      );
      document.documentElement.style.setProperty(
        '--scrollbar-width',
        window.innerWidth - scrollWidth + 'px'
      );
    };
    updateGlobal();
    window.addEventListener('resize', updateGlobal);

    return () => {
      window.removeEventListener('resize', updateGlobal);
    };
  }, []);

  useEffect(() => {
    const onScrollBar = () => {
      const scrollBarGap =
        window.innerWidth - document.documentElement.clientWidth;

      document.documentElement.style.setProperty(
        '--scrollbar',
        `${scrollBarGap}px`
      );
    };

    onScrollBar();
    window.addEventListener('load', onScrollBar);
    window.addEventListener('resize', onScrollBar);
    return () => {
      window.removeEventListener('load', onScrollBar);
      window.removeEventListener('resize', onScrollBar);
    };
  }, []);

  useEffect(() => {
    const onScreenLoad = () => {
      const vh = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      );
      document.documentElement.style.setProperty('--svh', `${vh}px`);
    };

    onScreenLoad();
    window.addEventListener('load', onScreenLoad);

    return () => {
      window.removeEventListener('load', onScreenLoad);
    };
  }, []);
};
