import { styled, withDisplayName } from '@/stitches.config';

const _Text = styled('span', {
  maxHeight: '999999px',
  position: 'relative',
  display: 'block',
  color: '$gray900',
  fontFamily: '$regular',
  fontStyle: 'normal',
  fontWeight: 'normal',
  margin: '$space-0',
  '& small': {
    fontSize: '80%',
  },
  '& strong, & b': {
    fontFamily: '$bold',
    fontStyle: 'normal',
    fontWeight: 'normal',
  },
  '& em, & i': {
    fontStyle: 'normal',
  },
  '& u': {
    textDecoration: 'underline',
  },
  '& p': {
    margin: '$space-0',
  },
  '& ul, & ol': {
    listStyle: 'none',
    margin: '$space-0',
    padding: '$space-0',
  },
  '& ol': {
    display: 'table',
    counterReset: 'listitem',
    '& > li': {
      '&:not(:only-child)': {
        display: 'table-row',
        '&::before': {
          content: 'counters(listitem, ".") ". "',
          display: 'table-cell',
          paddingRight: '$space-2',
          counterIncrement: 'listitem',
          textAlign: 'right',
          whiteSpace: 'nowrap',
        },
      },
      '&:not(:first-child)': {
        '&::before': {
          paddingTop: '0.5em',
        },
      },
    },
  },
  '& ul': {
    display: 'grid',
    rowGap: '0.5em',
    '& > li': {
      position: 'relative',
      display: 'block',
      paddingLeft: '$space-4',
      '&::before': {
        content: '',
        position: 'relative',
        display: 'inline-block',
        verticalAlign: 'middle',
        size: '$size-1',
        bc: '$primary',
        marginLeft: '-$space-4',
        marginRight: '$space-3',
      },
      '& ol, & ul': {
        marginTop: '$space-2',
      },
    },
  },
  '& a': {
    color: '$primary',
    textDecoration: 'underline',
    'text-underline-position': 'from-font',
    transitionProperty: 'color',
    transitionDuration: '0.3s',
    transitionTimingFunction: 'ease',
    '@hover': {
      '&:hover': {
        color: '$primary100',
      },
    },
  },
  '& > *:first-child': {
    '& ~ p, & ~ ul, & ~ ol': {
      marginTop: '0.5em',
    },
    '& ~ iframe, & ~ img, & ~ table, & ~ blockquote': {
      my: '1em',
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  '& blockquote': {
    position: 'relative',
    paddingLeft: '$space-6',
    '&::before': {
      content: `url("data:image/svg+xml,%3Csvg width='15' height='11' viewBox='0 0 15 11' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 1.72c-1.96.95-2.94 2.07-2.94 3.36.83.1 1.53.44 2.07 1.02.55.58.82 1.25.82 2.01a2.81 2.81 0 0 1-2.8 2.89c-.9 0-1.68-.36-2.33-1.09a3.78 3.78 0 0 1-1-2.63c0-3.1 1.76-5.53 5.26-7.28L15 1.72zm-8.83 0c-1.97.95-2.96 2.07-2.96 3.36.85.1 1.55.44 2.1 1.02.54.58.82 1.25.82 2.01A2.82 2.82 0 0 1 3.3 11 3 3 0 0 1 .98 9.91 3.8 3.8 0 0 1 0 7.28C0 4.18 1.74 1.75 5.23 0l.94 1.72z' fill='%23ED1C24' /%3E%3C/svg%3E")`,
      position: 'absolute',
      left: 0,
      top: 0,
      lineHeight: 0,
    },
    '&::after': {
      content: `url("data:image/svg+xml,%3Csvg width='15' height='11' viewBox='0 0 15 11' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-0.00088501 9.28C1.95912 8.33 2.93911 7.21 2.93911 5.92C2.10911 5.82 1.40911 5.48 0.869115 4.9C0.319115 4.32 0.0491152 3.65 0.0491152 2.89C0.0384371 2.51515 0.102897 2.14195 0.238697 1.7924C0.374497 1.44285 0.578889 1.12402 0.839829 0.854689C1.10077 0.585361 1.41298 0.370987 1.75806 0.2242C2.10314 0.0774132 2.47411 0.00118247 2.84912 0C3.74912 0 4.52912 0.36 5.17912 1.09C5.83847 1.80532 6.19664 2.74731 6.17912 3.72C6.17912 6.82 4.41912 9.25 0.919115 11L-0.00088501 9.28ZM8.82912 9.28C10.7991 8.33 11.7891 7.21 11.7891 5.92C10.9391 5.82 10.2391 5.48 9.68912 4.9C9.14912 4.32 8.86912 3.65 8.86912 2.89C8.85975 2.51298 8.92611 2.13791 9.06425 1.78699C9.2024 1.43607 9.40954 1.11642 9.6734 0.846963C9.93726 0.577505 10.2525 0.363712 10.6004 0.218233C10.9484 0.0727544 11.322 -0.00145364 11.6991 0C12.1423 -0.00097162 12.5802 0.0962727 12.9814 0.284741C13.3825 0.47321 13.7369 0.748221 14.0191 1.09C14.6694 1.80949 15.02 2.7504 14.9991 3.72C14.9991 6.82 13.2591 9.25 9.76912 11L8.82912 9.28Z' fill='%23ED1C24'/%3E%3C/svg%3E")`,
      position: 'relative',
      marginLeft: '$space-2',
      top: '-$space-1-5',
    },
  },
  '& table': {
    tableLayout: 'fixed',
    borderCollapse: 'collapse',
    borderSpacing: 0,
    width: '100%',
    bc: '$white',
    mx: 'auto',
    '& th, & td': {
      position: 'relative',
      px: '$space-2',
      py: '$space-2',
      fontStyle: 'normal',
      fontWeight: 'normal',
      wordBreak: 'break-word',
      verticalAlign: 'baseline',
    },
    '& thead': {
      '& th, & td': {
        verticalAlign: 'center',
        textAlign: 'center',
        bc: '$primary100',
        color: '$white',
        fontFamily: '$bold',
        borderRight: '1px solid $white',
        borderTop: '1px solid $white',
        '&:last-child': {
          borderRight: 0,
        },
      },
    },
    '& tbody': {
      '& th, & td': {
        textAlign: 'left',
        border: '1px solid $gray400',
      },
      '& th': {
        fontFamily: '$bold',
      },
    },
  },
  '& sup': {
    lineHeight: 0,
  },
  '& sub': {
    fontSize: '62.5%',
    lineHeight: '0px',
  },
  '&.text-blue a': {
    color: '$blue',
  },
  '&.text-green a': {
    color: '$green',
  },
  variants: {
    font: {
      base: {
        fontFamily: '$regular',
      },
      bold: {
        fontFamily: '$bold',
      },
      display: {
        fontFamily: '$eurothai',
      },
    },
    size: {
      'text-display': {
        fontFamily: '$eurothai',
        fontSize: `clamp(140px, ${-360 * ((240 - 140) / 920) + 140}px + ${
          ((240 - 140) / 920) * 100
        }vw, 240px)`,
        lineHeight: '90%',
        textTransform: 'uppercase',
      },
      'text-h1': {
        fontFamily: '$eurothai',
        fontSize: `clamp(44px, ${-360 * ((64 - 44) / 920) + 44}px + ${
          ((64 - 44) / 920) * 100
        }vw, 64px)`,
        lineHeight: '100%',
        textTransform: 'uppercase',
      },
      'text-h2': {
        fontFamily: '$eurothai',
        fontSize: `clamp(32px, ${-360 * ((48 - 32) / 920) + 32}px + ${
          ((48 - 32) / 920) * 100
        }vw, 48px)`,
        lineHeight: '100%',
        textTransform: 'uppercase',
      },
      'text-h3': {
        fontFamily: '$eurothai',
        fontSize: `clamp(24px, ${-360 * ((36 - 24) / 920) + 24}px + ${
          ((36 - 24) / 920) * 100
        }vw, 36px)`,
        lineHeight: '125%',
        textTransform: 'uppercase',
      },
      'text-sub-h1': {
        fontSize: `clamp(20px, ${-360 * ((24 - 20) / 920) + 20}px + ${
          ((24 - 20) / 920) * 100
        }vw, 24px)`,
        lineHeight: '125%',
      },
      'text-sub-h2': {
        fontSize: `clamp(18px, ${-360 * ((20 - 18) / 920) + 18}px + ${
          ((20 - 18) / 920) * 100
        }vw, 20px)`,
        lineHeight: '125%',
      },
      'text-body-1': {
        fontSize: `clamp(16px, ${-360 * ((18 - 16) / 920) + 16}px + ${
          ((18 - 16) / 920) * 100
        }vw, 18px)`,
        lineHeight: '150%',
      },
      'text-body-2': {
        fontSize: `clamp(14px, ${-360 * ((16 - 14) / 920) + 14}px + ${
          ((16 - 14) / 920) * 100
        }vw, 16px)`,
        lineHeight: '150%',
      },
      'text-base': {
        fontSize: 16,
        lineHeight: '150%',
      },
      'text-base-2': {
        fontSize: 14,
        lineHeight: '150%',
      },
      'text-caption': {
        fontSize: 12,
        lineHeight: '150%',
      },
      'text-button-1': {
        fontSize: 16,
        lineHeight: '112.5%',
      },
      'text-button-2': {
        fontSize: 14,
        lineHeight: '112.5%',
      },
      '2xs': {
        fontSize: 13,
        lineHeight: '150%',
      },
      sm: {
        fontSize: 15,
        lineHeight: '150%',
      },
      // text-body-1
      lg: {
        fontSize: 18,
        lineHeight: '150%',
      },
      // text-sub-h2
      h5: {
        fontSize: `clamp(18px, ${-360 * ((20 - 18) / 920) + 18}px + ${
          ((20 - 18) / 920) * 100
        }vw, 20px)`,
        lineHeight: '150%',
      },
      // text-sub-h1
      h4: {
        fontSize: `clamp(20px, ${-360 * ((24 - 20) / 920) + 20}px + ${
          ((24 - 20) / 920) * 100
        }vw, 24px)`,
        lineHeight: '140%',
      },
      display3: {
        fontSize: `clamp(24px, ${-360 * ((32 - 24) / 920) + 24}px + ${
          ((32 - 24) / 920) * 100
        }vw, 32px)`,
        lineHeight: '140%',
      },
      // text-h3
      h3: {
        fontFamily: '$eurothai',
        fontSize: `clamp(24px, ${-360 * ((36 - 24) / 920) + 24}px + ${
          ((36 - 24) / 920) * 100
        }vw, 36px)`,
        lineHeight: '100%',
        textTransform: 'uppercase',
      },
      h2: {
        fontFamily: '$eurothai',
        fontSize: `clamp(36px, ${-360 * ((40 - 36) / 920) + 36}px + ${
          ((40 - 36) / 920) * 100
        }vw, 40px)`,
        lineHeight: '100%',
        textTransform: 'uppercase',
      },
      h1: {
        fontFamily: '$eurothai',
        fontSize: `clamp(40px, ${-360 * ((48 - 40) / 920) + 40}px + ${
          ((48 - 40) / 920) * 100
        }vw, 48px)`,
        lineHeight: '100%',
        textTransform: 'uppercase',
      },
    },
    'line-height': {
      '75': {
        lineHeight: '$75 !important',
      },
      '100': {
        lineHeight: '$100 !important',
      },
      '125': {
        lineHeight: '$125 !important',
      },
      '150': {
        lineHeight: '$150 !important',
      },
    },
    variant: {
      'text-current': {
        color: '$current',
      },
      'text-black': {
        color: '$black',
      },
      'text-gray-darkest': {
        color: '$gray900',
      },
      'text-gray-dark': {
        color: '$gray800',
      },
      'text-gray': {
        color: '$gray700',
      },
      'text-gray-light': {
        color: '$gray600',
      },
      'text-gray-500': {
        color: '$gray500',
      },
      'text-gray-lightest': {
        color: '$gray200',
      },
      'text-disabled': {
        color: '$gray400',
      },
      'text-white': {
        color: '$white',
      },
      'text-red': {
        color: '$primary',
      },
      'text-red-200': {
        color: '$primary200',
      },
      'text-red-dark': {
        color: '$primary100',
      },
      'text-blue': {
        color: '$blue',
      },
      'text-blue-dark': {
        color: '$blue-dark',
      },
      'text-green': {
        color: '$green',
      },
      'text-lime': {
        color: '$lime',
      },
      'text-silver': {
        color: '$silver',
      },
      'text-bronze': {
        color: '$bronze',
      },
      'text-gold-medium': {
        color: '$gold-medium',
      },
    },
    theme: {
      light: {
        '& strong': {
          color: '$gray900',
        },
      },
      dark: {
        '& strong': {
          color: '$gray500',
        },
      },
    },
    wrap: {
      true: {
        '& span': {
          display: 'inline-block',
          wordWrap: 'break-word',
          overflowWrap: 'break-word',
          '-webkit-hyphens': 'auto',
          '-moz-hyphens': 'auto',
          hyphens: 'auto',
        },
      },
    },
    noWrap: {
      true: {
        whiteSpace: 'nowrap',
      },
    },
    'sr-only': {
      true: {
        position: 'absolute',
        width: '1px',
        height: '1px',
        padding: '$space-0',
        margin: '-1px',
        overflow: 'hidden',
        clip: 'rect(0, 0, 0, 0)',
        border: '0',
      },
    },
    line: {
      true: {
        marginBottom: '24px',
        '&::after': {
          content: '""',
          size: '32px',
          display: 'block',
          bc: '$primary',
          marginTop: '$space-4',
        },
      },
    },
    lines: {
      1: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
      2: {
        whiteSpace: 'initial',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
      },
      3: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': '3',
        '-webkit-box-orient': 'vertical',
      },
      4: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': '4',
        '-webkit-box-orient': 'vertical',
      },
      5: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': '5',
        '-webkit-box-orient': 'vertical',
      },
    },
    textTransform: {
      uppercase: {
        textTransform: 'uppercase',
      },
      none: {
        textTransform: 'none',
      },
    },
    lineThrough: {
      true: {
        textDecoration: 'line-through',
      },
    },
    underline: {
      true: {
        textDecoration: 'underline',
        'text-underline-position': 'from-font',
      },
    },
    transition: {
      true: {
        transitionProperty: 'color',
        transitionDelay: 'var(--transition-delay)',
        transitionDuration: 'var(--transition-duration)',
        transitionTimingFunction: 'var(--transition-easing)',
      },
    },
    'bullet-same-color': {
      true: {
        '& ul': {
          '& > li': {
            '&::before': { bc: 'CurrentColor' },
          },
        },
      },
    },
    'has-primary-remark': {
      true: {
        '& ul': {
          '& > li:last-child': {
            color: '$primary',
          },
        },
      },
    },
  },
  compoundVariants: [
    {
      line: 'true',
      size: 'h2',
      css: {
        marginBottom: '$space-6',
        '&::after': {
          marginTop: '$space-6',
        },
      },
    },
    {
      variant: 'text-red',
      theme: 'light',
      size: 'text-h1',
      css: {
        '& strong': {
          color: '$gray900',
        },
      },
    },
    {
      variant: 'text-red',
      theme: 'dark',
      size: 'text-h1',
      css: {
        '& strong': {
          color: '$white',
        },
      },
    },
  ],
  defaultVariants: {
    size: 'text-base',
    transition: 'true',
  },
});

export const Text = withDisplayName(_Text, 'Text') as typeof _Text;
